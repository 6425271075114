export default {
  debugMode: false,
  serverURL: '.',
  testingServerURL: '.',
  versionPath: '',

  tileCount: 10,
  spriteSize: 0,
  spread: 4000,
  defaultGap: 2.0,
  defaultZVariance: 110,
  mosaicDepthMultiplier: 0.33,
  enableMouseOver: true,
  timeBetweenMouseOverChecks: 100,
  enableAnimation: true,
  cameraStartY: -40,
  cameraStartZ: 150,
  // sceneTilt: 100,
  defaultZoomMin: 50,
  defaultZoomMax: 300,
  defaultFogNear: 100,
  defaultFogFar: 530,
  panSpeed: 0.055,
  panFriction: 0.07,
  tileAnimationStopCutoff: 0.01,
  tileSize: 16,
  // revealEnabled: true,
  enableCulling: true,
  enableHighResOverlay: true,
  emergencyTileShutoff: false, // THIS OVERRIDES THE HIGH RES TILES
  highResSize: 128,
  farFromMosaicZ: 350,
  renderDebugCamera: false,
  showOctreeHelper: false,
  colorizeHighRes: false,
  highResPoolSize: 60,
  doAntiAliasing: true,
  // repeatRatio: 1,
  autoBrightnessFix: true,
  brightnessFix: 0,
  revealOverrideMode: false,
  useLowResSprites: false
};
