const DataSet = {
  CATEGORY : 'category',
  ACTION : 'action',
  LABEL : 'label',
  VALUE : 'value'
};

const CLICK = 'click';

const CLICK_ELEMENT_SELECTOR = 'track-ga';

export default class Analytics {
  constructor() {
    this.clickEventElements =
        document.getElementsByClassName(CLICK_ELEMENT_SELECTOR);

    for (let i = 0; i < this.clickEventElements.length; i++) {
      let el = this.clickEventElements[i];
      el.addEventListener(CLICK, (event) => this.handleClick(event));
    }
  }

  handleClick(event) {
    const dataAttributes = event.currentTarget.dataset;
    const category = dataAttributes[DataSet.CATEGORY] || '';
    const action = dataAttributes[DataSet.ACTION] || CLICK;
    const label = dataAttributes[DataSet.LABEL] || '';
    const value = dataAttributes[DataSet.VALUE] || '';

    // gtag('event', action, {'event_category' : category, 'event_label' :
    // label});
    window.dataLayer.push({
      'event' : action,
      'eventCategory' : category,
      'eventLabel' : label,
      'eventValue' : value
    });
  }
}
