import 'whatwg-fetch'

import emitter from 'mitt';
import * as THREE from 'three';

import server from '../server'

import settings from './../settings'
import sceneState from './../state'
import SpriteSheet from './spriteSheet';
import Tile from './tile';
import TileCloudOverlays from './tileCloudOverlays';
import TileConfiguration from './TileConfiguration';
import TileGroup from './tileGroup';

import {
  PointOctree
} from "sparse-octree";
import OctreeHelper from "octree-helper";

export default class tileCloud {
  constructor() {
    this.tileGroups = [];

    this.objectGroup = new THREE.Group();

    // this.currentTileConfigurationID = 2;
    this.currentTileConfiguration = null;
    this.tileGridConfiguration = new TileConfiguration();
    this.tileRandomConfiguration = new TileConfiguration();
    this.tileMosaicConfiguration = new TileConfiguration();
    this.tileMosaicRandZConfiguration = new TileConfiguration();

    this.uniqueSocialPosts = 0;
    this.mosaicTotalTiles = 0;

    this.mosaicCols = 0;
    this.mosaicRows = 0;

    this.cameraFrustum = null;
    this.cameraPosition = null;
    this.timeOfLastFrustumCull = 0;
    this.timeOfLastHighResCheck = 0;

    this.tileCloudOverlays = new TileCloudOverlays();
    this.objectGroup
      .add(this.tileCloudOverlays.group)

    this.needToRecalculateOctree = true;
    this.isUserInteracting = false;

    this.octree = new PointOctree(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0));
    this.octreeHelper = new OctreeHelper(this.octree);
  }

  loadJsonAssets(json) {
    this.uniqueSocialPosts = json.totalImagesCollected;
    this.mosaicTotalTiles = json.totalImagesRequired;

    const promises = [];

    this.mosaicCols = parseInt(json.width);
    this.mosaicRows = parseInt(json.height);

    const sheetIds = json.sheets;

    let unused = 0;
    let unique = 0;

    sheetIds.forEach((id) => {
      const promise = this.loadSocialPostData(id).then((data) => {
        const tileGroup = new TileGroup(id, this.getTotalTiles());
        this.objectGroup.add(tileGroup.group);
        this.tileGroups.push(tileGroup);

        tileGroup.initFromJSON(data, this.mosaicCols, this.mosaicRows)
        unused += tileGroup.countUnusedTiles;
        unique += tileGroup.countUniqueTiles;
      })
      promises.push(promise);
    })

    return Promise.all(promises).then(() => {
      this.updateGridConfiguration();
      this.updateRandomConfiguration();
      this.updateMosaicConfiguration();
      this.updateMosaicRandConfiguration();

      this.updateConfiguration(0);

      this.hideRepeatedTiles();
    })
  }

  loadLoResImages() {
    return this.tileGroups.map(
      (tileGroup) => {
        return tileGroup.loadLoResTexture();
      })
  }

  loadSocialPostData(id) {
    return server.loadData(`sprites${id}.json`, settings.versionPath)
      .then((json) => {
        return json
      });
  }
  hideRepeatedTiles() {
    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      tileGroup.hideRepeatedTiles(sceneState.repeatRatio);
    }
  }

  getTotalTiles() {
    let total = 0;
    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      total += tileGroup.tiles.length;
    }
    return total;
  }
  getTileByOverallIndex(index) {
    let groupIndex = index;
    let tile = null;

    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      if (tile != null)
        break;

      if (groupIndex < tileGroup.tiles.length) {
        tile = tileGroup.tiles[groupIndex];
      }
      groupIndex -= tileGroup.tiles.length;
    }

    if ((tile === null) || (tile === undefined))
      console.error("TILE NOT FOUND for index: " + index)

    return tile;
  }

  setCameraFrustum(frustum) {
    this.cameraFrustum = frustum;
  }
  setCameraPosition(position) {
    this.cameraPosition = position;
  }

  updateRandomConfiguration() {
    this.tileRandomConfiguration.initRandom(this.getTotalTiles(),
      settings.spread);
  }
  updateGridConfiguration() {
    const targetAspectRatio = 6 / 4;
    this.tileGridConfiguration.initGrid(this.getTotalTiles(), targetAspectRatio,
      settings.tileSize, sceneState.gap);

    this.tileGridConfiguration.setZfromRandomVariance(sceneState.zVariance);
  }
  updateMosaicConfiguration() {
    this.tileMosaicConfiguration.initMosaicConfiguration(
      this.mosaicCols, this.mosaicRows, settings.tileSize, sceneState.gap);
    this.tileMosaicConfiguration.setZfromMosaicBrightness(
      this.tileGroups, settings.mosaicDepthMultiplier);
  }
  updateMosaicRandConfiguration() {
    this.tileMosaicRandZConfiguration.initMosaicConfiguration(
      this.mosaicCols, this.mosaicRows, settings.tileSize, sceneState.gap);
    this.tileMosaicRandZConfiguration.setZfromRandomVariance(
      sceneState.zVariance);
  }

  updateConfiguration(time) {
    const id = sceneState.tileLayout;

    if (id == 0) {
      this.moveTilesToConfiguration(this.tileRandomConfiguration, time);
    } else if (id == 1) {
      this.moveTilesToConfiguration(this.tileGridConfiguration, time);
    } else if (id == 2) {
      this.moveTilesToConfiguration(this.tileMosaicConfiguration, time);
    } else if (id == 3) {
      this.moveTilesToConfiguration(this.tileMosaicRandZConfiguration,
        time);
    }

    if (sceneState.doCulling) {
      this.needToRecalculateOctree = true;
    }
  }

  moveTilesToConfiguration(configuration, time) {
    this.currentTileConfiguration = configuration;
    const totalTiles = this.getTotalTiles();
    const tileGroupsToAnimate = this.tileGroups; // animate all for now
    for (var i = 0; i < totalTiles; i++) {
      const tile = this.getTileByOverallIndex(i);

      const index = configuration.isMosaic ? tile.mosaicIndex : i;
      const target = configuration.getTileTarget(index)

      if (tile) {
        if (time == 0) {
          tile.pos.copy(tile.targetPos);
        }
        tile.startPos.copy(tile.pos);
        tile.targetPos.set(target.x, target.y, target.z);
      }
    }

    for (var i = 0; i < tileGroupsToAnimate.length; i++) {
      const tileGroup = tileGroupsToAnimate[i];
      tileGroup.startAnimation(time);
    }
  }

  getTotalImagesCollected() {
    return this.uniqueSocialPosts;
  }

  updateFog() {
    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      tileGroup.material.uniforms.fogNear.value = sceneState.fogNear;
      tileGroup.material.uniforms.fogFar.value = sceneState.fogFar;
      tileGroup.material.needsUpdate = true;
    }
  }
  updateBrightnessFixRatio() {
    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      tileGroup.material.uniforms.brightnessAdjustmentRatio.value =
        settings.brightnessFix;
      tileGroup.material.needsUpdate = true;
    }
  }

  prepForNormalRender() {
    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      tileGroup.material.uniforms.doPickDraw.value = false;
      tileGroup.material.needsUpdate = true;
    }

    if (settings.enableHighResOverlay) {
      this.tileCloudOverlays.group.visible = true;
    } else {
      this.tileCloudOverlays.group.visible = false;
    }
  }
  prepForPickRender() {
    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      tileGroup.material.uniforms.doPickDraw.value = true;
      tileGroup.material.needsUpdate = true;
    }
    this.tileCloudOverlays.group.visible = false;
  }

  update() {
    if (this.needToRecalculateOctree) {
      this.calculateOctree();
      this.needToRecalculateOctree = false;
    }

    // check brightness tweak
    if (settings.autoBrightnessFix) {
      const brightnessFixRatio =
        this.map(this.cameraPosition.z, 300, 1000, 0, 1);
      if (brightnessFixRatio != settings.brightnessFix) {
        settings.brightnessFix = brightnessFixRatio;
        this.updateBrightnessFixRatio();
      }
    }

    // frustum culling
    let doFrustumCull = false;
    if (sceneState.doCulling) {
      const timeSinceCull = Date.now() - this.timeOfLastFrustumCull;
      if (timeSinceCull > 50) {
        doFrustumCull = true;
        this.timeOfLastFrustumCull = Date.now();
        this.cullByOctree();
      }
    }

    // check if we need to load any high res images
    // don't do on the same frame as a cull
    let doHighResCheck = false;
    if (settings.enableHighResOverlay && !doFrustumCull && !this.isUserInteracting) {
      const timeSinceHighResCheck = Date.now() - this.timeOfLastHighResCheck;
      if (timeSinceHighResCheck > 100) {
        // if we're not too far from the mosaic
        if (this.cameraPosition.z < settings.farFromMosaicZ)
          doHighResCheck = true;
        this.timeOfLastHighResCheck = Date.now();
      }
    }

    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];

      if (doHighResCheck) {
        tileGroup.updateVisibleTilesDistance(this.cameraPosition);
      }

      tileGroup.update();
    }

    if (doHighResCheck) {
      this.tileCloudOverlays.updateHighResTiles(this.tileGroups, this.cameraFrustum);
    }
  }

  resetAllCulling() {
    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      tileGroup.updateTileVisibilityBasedOnCulling();
    }
  }

  getRatioInViewAtOctreeLevel(level) {
    const octants = this.octree.findOctantsByLevel(level);
    let inViewCount = 0;
    const box = new THREE.Box3();
    for (var i = 0; i < octants.length; i++) {
      const octant = octants[i];
      box.set(octant.min, octant.max);
      const isInView = this.cameraFrustum.intersectsBox(box);
      if (isInView) {
        inViewCount++;
      }
    }
    return (inViewCount / octants.length);
  }
  cullByOctree(frustum) {
    const inViewRatio = this.getRatioInViewAtOctreeLevel(2);

    // if too much is in view, don't cull
    if (inViewRatio > 0.3) {
      this.resetAllCulling();
      return;
    }

    // check which tiles are in the view frustum
    let intersectOctants = this.octree.cull(this.cameraFrustum);
    let intersectTiles = [];
    let intersectTilesByTileGroup = [];
    let total = 0;
    for (var i = 0; i < intersectOctants.length; i++) {
      const pointOctant = intersectOctants[i];
      if (pointOctant.data !== null) {
        //intersectTiles = intersectTiles.concat(pointOctant.data)
        total += pointOctant.data.length;
        for (var j = 0; j < pointOctant.data.length; j++) {
          const tile = pointOctant.data[j];
          const tileGroupID = tile.tileGroup.id;

          if (intersectTilesByTileGroup[tileGroupID] == null) {
            intersectTilesByTileGroup[tileGroupID] = [];
          }

          intersectTilesByTileGroup[tileGroupID].push(tile);
        }
      }
    }

    // intersectTilesByTileGroup.forEach((tileArray, id) => {
    //   const tileGroup = this.tileGroups[id];
    //   tileGroup.updateTileVisibilityBasedOnCulling(tileArray);
    // })

    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];
      const tileArray = intersectTilesByTileGroup[tileGroup.id];
      if (tileArray != null) {
        tileGroup.updateTileVisibilityBasedOnCulling(tileArray);
      }
    }
  }
  calculateOctree() {
    if (this.octree) {
      //console.log('TODO: destroy old OCTREE')
    }

    const {
      minBounds,
      maxBounds
    } = this.getBoundsOfCurrentConfiguration();

    this.octree = new PointOctree(minBounds, maxBounds);

    // place tiles in octree
    for (let tg = 0; tg < this.tileGroups.length; tg++) {
      const tileGroup = this.tileGroups[tg];

      for (var i = 0; i < tileGroup.tiles.length; i++) {
        const tile = tileGroup.tiles[i];

        this.octree.put(tile.targetPos, tile);
      }
    }

    if (settings.showOctreeHelper) {
      this.octreeHelper.octree = this.octree;
      this.octreeHelper.update();
    }
  }

  getBoundsOfCurrentConfiguration() {
    const minBounds = new THREE.Vector3();
    const maxBounds = new THREE.Vector3();
    this.currentTileConfiguration.tileTargets.forEach((tilePos) => {
      if (tilePos.x < minBounds.x)
        minBounds.x = Math.floor(tilePos.x) - 1;
      if (tilePos.y < minBounds.y)
        minBounds.y = Math.floor(tilePos.y) - 1;
      if (tilePos.z < minBounds.z)
        minBounds.z = Math.floor(tilePos.z) - 1;
      if (tilePos.x > maxBounds.x)
        maxBounds.x = Math.ceil(tilePos.x) + 1;
      if (tilePos.y > maxBounds.y)
        maxBounds.y = Math.ceil(tilePos.y) + 1;
      if (tilePos.z > maxBounds.z)
        maxBounds.z = Math.ceil(tilePos.z) + 1;
    })
    return {
      minBounds,
      maxBounds
    };
  }


  map(num, in_min, in_max, out_min, out_max) {
    const val =
      (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    return Math.min(out_max, Math.max(out_min, val));
  }
}