const SHARE_LINK = 'share-link';

const SocialLinks = {
  FACEBOOK: 'https://www.facebook.com/sharer/sharer.php?u=',
  TWITTER: 'https://twitter.com/intent/tweet?text=',
};

const Networks = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
}

export default class SocialShare {
  constructor() {
    this.shareLinks = document.getElementsByClassName(SHARE_LINK);
    this.addEventListeners();
  }

  addEventListeners() {
    for (let i = 0; i < this.shareLinks.length; i++) {
      this.shareLinks[i].addEventListener('click',
        (event) => this.getShareLinks(event));
    }
  }

  getShareLinks(event) {
    event.stopPropagation();

    const network = event.currentTarget.getAttribute('data-network');
    const popupOptions = 'menubar=no,toolbar=no,resizable=yes,' +
      'scrollbars=yes,height=253,width=600';

    let el = event.target;
    let content;
    let href;
    let params;
    let url = `${window.location.href}`;

    switch (network) {
      case Networks.FACEBOOK:
        href = `${SocialLinks.FACEBOOK + url}`;
        break;
      case Networks.TWITTER:
        href = `${SocialLinks.TWITTER}&url=${url}`;
        break;
    }

    window.open(href, ``, popupOptions);
  }
}