import * as THREE from 'three';

export default class Tile {
  constructor() {
    this.index = null;
    this.attributeIndex = null;

    this.tileGroup = null;

    this.mosaicIndex = null;
    this.mosaicBrightness = null;

    this.imageID = null;
    this.socialPost = null;

    this.pos = new THREE.Vector3();
    this.startPos = new THREE.Vector3();
    this.targetPos = new THREE.Vector3();
    this.animationVector = new THREE.Vector3();

    this.spritesheetID_default = null;

    this.spritesheetID = null;
    this.spriteIndex = 0;
    this.spriteSheet = null;
    this.spriteXY = null;
    this.spriteUV = null;

    this.isAnimating = false;
    this.isVisible = true;
    this.isCulled = false;
    this.wasInFrustrumThisUpdate = true;

    this.isHiddenRepeat = false;
    this.isOverlayed = false;
    this.distanceToCameraSquared = 0;
    this.distanceToCameraZ = 0;

    this.needsPositionUpdate = false;
  }

  setSpriteSheet(spriteSheet) {
    this.spriteSheet = spriteSheet;

    //this.spriteIndex = this.index % spriteSheet.tilesPerSheet;
    this.spriteXY = spriteSheet.getSpritePosition(this.spriteIndex);
    this.spriteUV = spriteSheet.getSpriteUV(this.spriteIndex);
  }

}