export default {
  fogNear: 0,
  fogFar: 0,
  zoomMin: 0,
  zoomMax: 0,
  tileLayout: 0,
  gap: 0,
  zVariance: 0,
  canReveal: false,
  repeatRatio: 0.0,
  doCulling: false
}