import { init } from '@sentry/browser';

const devHosts = ['localhost', '0.0.0.0', '10.0.2.2'];
let isDevHost = devHosts.filter(host => window.location.href.includes(host));

if (!isDevHost.length) {
  init({
    dsn: 'https://61cdcaf581bf429a9b931a2375b90d39@sentry.io/1292435'
  });
}
