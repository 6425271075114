export default class SpriteSheet {
  constructor(scene) {
    this.filename = "";
    this.filenameLowRes = "";
    this.filenameHighRes = "";
    this.width = this.height = 0;
    this.tileSize = 0;

    this.rows = this.cols = 0;
    this.tilesPerSheet = 0;

    this.id = null;
    this.isLoaded = false;
    this.texture = null;
  }

  initFromJSON(id, imagePath) {
    this.id = id;
    this.filename = `sprite${id}.jpg`;
    this.filenameLowRes = `sprite${id}_low.jpg`;
    this.filenameHighRes = `sprite${id}_high.jpg`;
    //this.filename = "server/sheets/sheet_32.jpg";
    //this.width = this.height = 2048;
    this.width = this.height = 2048;
    this.tileSize = 32;

    this.rows = Math.floor(this.height / this.tileSize);
    this.cols = Math.floor(this.width / this.tileSize);
    this.tilesPerSheet = this.rows * this.cols;
  }

  getSpritePosition(index) {
    const col = index % this.cols;
    const row = Math.floor(index / this.cols);

    const x = col * this.tileSize;
    const y = row * this.tileSize;
    //const y = ((this.rows - 1) - row) * this.tileSize;

    return {
      x,
      y
    };
  }
  getSpriteUV(index) {
    const pos = this.getSpritePosition(index);

    const u = pos.x / this.width;
    const v = ((this.height - this.tileSize) - pos.y) / this.height; // uv 0,0 is bottom left

    return {
      u,
      v
    };
  }
}