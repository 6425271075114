import randomGen from 'random-seed';

export default class TileConfiguration {
  constructor() {
    this.tileCount = 0;
    this.tileTargets = [];
    this.isMosaic = false;
  }


  setTileTarget(index, x, y, z) {
    this.tileTargets[index] = {
      x,
      y,
      z
    };
  }

  getTileTarget(index) {
    if (!this.tileTargets[index]) return ({
      x: 0,
      y: 0,
      z: 0
    });

    return this.tileTargets[index]
  }

  initGrid(count, aspectRatio, tileSize, gap, zVariation) {
    this.tileCount = count;

    const rows = Math.ceil(Math.sqrt(this.tileCount / (aspectRatio)));
    const cols = Math.ceil(this.tileCount / rows);

    for (var i = 0; i < this.tileCount; i++) {
      this.setTileInGrid(i, rows, cols, tileSize, gap)
    }

    this.setZfromRandomVariance(zVariation);
  }
  setTileInGrid(index, rows, cols, tileSize, gap) {
    const col = index % cols;
    const row = Math.floor(index / cols);

    const gridW = cols * (tileSize + gap);
    const gridH = rows * (tileSize + gap);

    const x = (col * (tileSize + gap)) - gridW / 2;
    const y = (row * (tileSize + gap)) - gridH / 2;
    const z = 0;

    this.setTileTarget(index, x, y, z);
  }

  initRandom(count, spreadDist) {
    this.tileCount = count;

    const seed = "ADSFASDF";
    const rand = randomGen.create(seed);

    for (var i = 0; i < this.tileCount; i++) {

      const x = rand.floatBetween(-spreadDist, spreadDist);
      const y = rand.floatBetween(-spreadDist, spreadDist);
      const z = rand.floatBetween(-spreadDist, spreadDist);

      this.setTileTarget(i, x, y, z);
    }
  }
  initMosaicConfiguration(width, height, tileSize, gap, tileGroups) {
    this.isMosaic = true;

    const rows = height;
    const cols = width;
    this.tileCount = rows * cols;

    for (var i = 0; i < this.tileCount; i++) {
      this.setTileInGrid(i, rows, cols, tileSize, gap)

      // mosiac is flipped upsided down.. not sure why
      this.tileTargets[i].y *= -1;
    }
  }

  setZfromRandomVariance(zVariation) {
    const seed = "ADSFASDF";
    const rand = randomGen.create(seed);

    for (var i = 0; i < this.tileTargets.length; i++) {
      this.tileTargets[i].z = rand.floatBetween(-zVariation, zVariation);
    }
  }
  setZfromMosaicBrightness(tileGroups, multiplier) {
    if (tileGroups) {
      tileGroups.forEach((tileGroup) => {
        tileGroup.tiles.forEach((tile) => {
          this.tileTargets[tile.mosaicIndex].z = tile.mosaicBrightness * multiplier;
        })
      })
    }
  }
}