const userAgent = navigator.userAgent;

const isAndroid = /android/i.test(userAgent);
const isIOs = /iphone|ipod|ipad/i.test(userAgent);
const isMobile = isIOs || /android|iemobile/i.test(userAgent);
const isSafari = /safari/i.test(userAgent) && !/chrome|chromium/i.test(userAgent);
const isTablet = (isAndroid && !/mobile/i.test(userAgent)) // https://stackoverflow.com/questions/5341637/how-do-detect-android-tablets-in-general-useragent
  ||
  /ipad/i.test(userAgent);
const isMSEdge = /Edge/i.test(userAgent);
const isChrome = /chrome|chromium/i.test(userAgent) && !isMSEdge;

const feature = {
  isAndroid,
  isIOs,
  isMobile,
  isSafari,
  isTablet,
  isMSEdge,
  isChrome
}

export default feature;