/**
 * Application entry point
 */

// Load application styles

import 'styles/index.scss';
import './lib/error-tracking';

import * as dat from 'dat.gui';

import Analytics from './analytics'
import feature from './feature'
import * as Stats from './lib/stats'
import {
  queryData
} from './lib/url';
import WEBGL from './lib/webGL-detector';
import SceneManager from './scene/sceneManager';
import settings from './settings';
import SocialShare from './social-share';
import sceneState from './state';
import UiInteraction from './ui.js';

// ================================
// START YOUR APP HERE
// ================================

if (process.env.NODE_ENV === 'development') {
  console.log("starting Developement build")
  //  settings.serverURL = settings.testingServerURL;
}

// check query string or settings for debug mode
settings.debugMode = settings.debugMode || (queryData.debug == true);
settings.revealOverrideMode =
  settings.revealOverrideMode || (queryData.reveal == true);

let sceneManager;
let stats = null;
let lastPickTime = 0;
const mouse = {
  x: 0,
  y: 0
};
const mouseDownStart = {
  x: 0,
  y: 0
};
const touchStart = {
  x: 0,
  y: 0
};

let analytics;
let socialShare;
let uiInteraction;

let webGlSupported = false;
let sceneCreatedSucessfully = false;

// equivilent of jQuery's "ready()"
function ready(fn) {
  if (document.attachEvent ? document.readyState === "complete" :
    document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

function displayApplication() {
  document.body.classList.add('ready');
}

ready(() => {
  analytics = new Analytics();
  socialShare = new SocialShare();
  uiInteraction = new UiInteraction();

  uiInteraction.addAnimatedGif();

  // mobile settings
  if (feature.isMobile) {
    settings.enableMouseOver = false;
    settings.highResPoolSize = 40;
    settings.useLowResSprites = true;
  }

  sceneManager = new SceneManager(settings);


  if (WEBGL.isWebGLAvailable()) {
    webGlSupported = true;
    sceneCreatedSucessfully = sceneManager.prepare();
  }

  if (!webGlSupported || !sceneCreatedSucessfully) {
    let warning = WEBGL.getWebGLErrorMessage();

    let container = document.getElementById('webgl-error');
    container.appendChild(warning);
    container.classList.add('is-visible');
    document.getElementById('info-modal').style.backgroundColor = 'black';
  }

  window.addEventListener('resize', () => {
    sceneManager.resize();
  });

  const LOADER = document.getElementById('loader');

  // display document body
  displayApplication();

  // if we're in debug mode
  if (settings.debugMode) {
    document.body.classList.add('debug');

    // STATS
    stats = new Stats();
    document.body.appendChild(stats.dom);

    // DAT GUI
    initDATGUI();
  }

  if (webGlSupported) {
    // when all json assets are loaded
    sceneManager.loadJsonAssets()
      .then(() => {
        // start animating now so we can see the images pop in
        animate();

        return sceneManager.loadLoResImages(
          (loaded,
            total) => {
            uiInteraction.updateLoadProgress(loaded, total);
          });
      })
      .then(() => {
        LOADER.classList.add('is-loaded');
        const canvasEl = document.getElementById('canvasContainer');

        window.addEventListener('mousemove', onMouseMove)
        canvasEl.addEventListener('mousedown', onMouseDown)
        window.addEventListener('mouseup', onMouseUp)
        canvasEl.addEventListener('touchstart', onTouchStart);
        window.addEventListener('touchend', onTouchEnd);

        uiInteraction.addExploreEventListener(doExplore);

        if (sceneManager.canReveal()) {
          uiInteraction.addRevealEventListeners(doReveal);
          uiInteraction.addResetEventListeners(doReset);
        }

        const total = sceneManager.tileCloud.getTotalImagesCollected();
        uiInteraction.updateInfoModalImageCount(total);

        sceneManager.doSiteOpening();
      })
  }
})

function onMouseMove(e) {
  mouse.x = e.clientX;
  mouse.y = e.clientY;
}

function onMouseDown(e) {
  mouseDownStart.x = e.clientX;
  mouseDownStart.y = e.clientY;
  sceneManager.isUserInteracting = true;
}

function onMouseUp(e) {
  onClick(mouseDownStart.x, mouseDownStart.y, e.clientX, e.clientY)
  sceneManager.isUserInteracting = false;
}

function onTouchStart(e) {
  if (e.touches.length == 1) {
    touchStart.x = e.touches[0].pageX
    touchStart.y = e.touches[0].pageY
  }
  sceneManager.isUserInteracting = true;
}

function onTouchEnd(e) {
  onClick(touchStart.x, touchStart.y, e.changedTouches[0].pageX,
    e.changedTouches[0].pageY)
  sceneManager.isUserInteracting = false;
}

function onClick(startX, startY, upX, upY) {

  const dx = upX - startX;
  const dy = upY - startY;
  var distance = Math.sqrt(dx * dx + dy * dy);

  // if they didn't move far (aka, they're not panning or zooming)
  if (distance < 6) {
    const clickIndex = sceneManager.pick(startX, startY);
    if (clickIndex != -1)
      openPhotoModal(clickIndex);
  }
}

function animate(time) {
  if (stats)
    stats.begin();

  sceneManager.update();

  if (settings.enableMouseOver) {
    const timeSincePick = time - lastPickTime;
    if (timeSincePick > settings.timeBetweenMouseOverChecks) {
      lastPickTime = time;

      let isMouseOver = false;
      if (sceneManager.isUserInteracting) {
        isMouseOver = false;
      } else if (sceneManager.isCameraFarFromMosaic()) {
        isMouseOver = true;
      } else {
        // if (sceneManager.camera)
        const mouseOver = sceneManager.pick(mouse.x, mouse.y);
        isMouseOver = (mouseOver != -1);
      }

      let pointer = "auto";
      if (isMouseOver) {
        pointer = "pointer";
      }
      document.getElementById('canvasContainer').style.cursor = pointer;
    }
  }

  sceneManager.render();

  if (stats)
    stats.end();

  requestAnimationFrame(animate);
}

function openPhotoModal(tileIndex) {
  const socialInfo = sceneManager.getSocialInfoForTile(tileIndex);

  if (socialInfo)
    uiInteraction.openPhotoModal(socialInfo.imageURL);
}

function doExplore() {
  if (sceneManager.canReveal()) {
    setTimeout(uiInteraction.showRevealButton, 3000);
  }
  sceneManager.doStartScene();
}

function doReveal() {
  sceneManager.doReveal();
  setTimeout(uiInteraction.showResetButton, 7000);
}

function doReset() {
  sceneManager.doReset();
  setTimeout(uiInteraction.showRevealButton, 7000);
}

function initDATGUI() {

  const gui = new dat.GUI();
  gui.add(settings, 'enableMouseOver').name('mouse detection');
  gui.add(settings, 'enableHighResOverlay').name('high res overlays').listen();
  gui.add(settings, 'doAntiAliasing').name('anti-aliasing');
  gui.add(settings, 'autoBrightnessFix')
  gui.add(settings, 'brightnessFix')
    .min(0)
    .max(1)
    .listen()
    .onChange(() => {
      sceneManager.tileCloud.updateBrightnessFixRatio()
    });
  // gui.add(settings, 'tileCount', 1, 170000).onFinishChange((val) => {
  //   sceneManager.buildTiles(val)
  // });
  // gui.add(settings, 'spriteSize', {
  //   32: 0,
  //   64: 1,
  //   96: 2,
  //   128: 3
  // }).onFinishChange((val) => {
  //   sceneManager.changeSpriteSheet(val)
  // });

  // gui.add(settings, 'sceneTilt', 0,200 ).onChange(
  // sceneManager.updateSceneTilt)

  var layout = gui.addFolder('Layout Settings');
  layout
    .add(sceneState, 'tileLayout', {
      exploded: 0,
      grid_rand_z: 1,
      //  mosaic_w_depth: 2,
      mosaic_rand_z: 3
    })
    .name('tile layout')
    .onFinishChange(
      (val) => {
        sceneManager.tileCloud.updateConfiguration(4);
      });
  layout.add(settings, 'spread', 100, 8000)
    .name('exploded dist')
    .onChange((val) => {
      sceneManager.tileCloud.updateRandomConfiguration(val);
      sceneManager.tileCloud.updateConfiguration(2);
    });
  layout.add(settings, 'defaultGap', 0, 32).name('grid gap').onChange((val) => {
    sceneState.gap = settings.defaultGap;
    sceneManager.tileCloud.updateGridConfiguration();
    // sceneManager.tileCloud.updateMosaicConfiguration();
    sceneManager.tileCloud.updateMosaicRandConfiguration();
    sceneManager.tileCloud.updateConfiguration(2);
  });
  layout.add(settings, 'defaultZVariance', 0, 500)
    .name('rand z amt')
    .onChange((val) => {
      sceneState.zVariance = settings.defaultZVariance;
      sceneManager.tileCloud.updateGridConfiguration();
      sceneManager.tileCloud.updateMosaicRandConfiguration();
      sceneManager.tileCloud.updateConfiguration(2);
    });
  // layout.add(settings, 'mosaicDepthMultiplier', 0, 10).name('depth z
  // amt').onChange((val) => {
  //   sceneManager.tileCloud.updateMosaicConfiguration()
  //   sceneManager.tileCloud.updateConfiguration()
  // });
  layout.add(sceneState, 'repeatRatio', 0, 1)
    .name('fill missing ratio')
    .listen()
    .onChange((val) => {
      sceneManager.tileCloud.hideRepeatedTiles();
    });

  var camera = gui.addFolder('Camera Settings');
  camera.add(settings, 'panSpeed', 0, 0.4)
    .onChange(sceneManager.updateControlSettings)
  camera.add(settings, 'panFriction', 0, 0.4)
    .onChange(sceneManager.updateControlSettings)
  camera.add(settings, 'cameraStartY', -200, -1)
    .onChange(sceneManager.updateCamera)
  camera.add(settings, 'cameraStartZ', 1, 500)
    .onChange(sceneManager.updateCamera)
  camera.add(sceneState, 'zoomMin', 0, 500)
    .onChange(sceneManager.updateControlSettings)
  camera.add(sceneState, 'zoomMax', 100, 4000)
    .onChange(sceneManager.updateControlSettings)
  camera.add(settings, 'defaultFogNear', 0, 600)
    .name('fog near')
    .onChange(() => {
      sceneState.fogNear = settings.defaultFogNear;
      sceneManager.updateFog();
    });
  camera.add(settings, 'defaultFogFar', 0, 800).name('fog far').onChange(() => {
    sceneState.fogFar = settings.defaultFogFar;
    sceneManager.updateFog();
  });

  var optomization = gui.addFolder('Optimization');
  optomization.add(settings, 'enableCulling')
    .onChange(() => {
      sceneState.goCulling = settings.enableCulling;
    })
  // optomization.add(settings, 'enableFrustumCulling')

  var debug = gui.addFolder('Debugging');
  debug.add(settings, 'renderDebugCamera')
  debug.add(settings, 'colorizeHighRes')
  debug.add(settings, 'showOctreeHelper').onChange(() => {
    if (settings.showOctreeHelper) {
      sceneManager.tileCloud.calculateOctree();
    }
  })
}