import * as THREE from 'three';
import Tile from './tile';
import settings from './../settings'


class TileOverlay {
  constructor() {
    this.mesh = null;
    this.tile = null;
  }
  updateMeshPositionFromTile() {
    this.mesh.position.copy(this.tile.pos);
    this.mesh.position.z += 0.01;
  }
}

export default class TileCloudOverlays {
  constructor() {
    this.group = new THREE.Group();

    this.overlayPoolSize = settings.highResPoolSize;
    this.overlayPool = [];

    this.availableOverlays = [];

    this.nearByDistanceZ = 100;

    this.textureLoader = new THREE.TextureLoader();


    this.totalLoaded = 0;

    this.buildPool();
  }

  buildPool() {
    for (var i = 0; i < this.overlayPoolSize; i++) {
      const newTileOverlay = new TileOverlay();
      const tileColor = 0xffffff;

      var geometry = new THREE.PlaneGeometry(settings.tileSize, settings.tileSize, 1, 1);
      var material = new THREE.MeshBasicMaterial({
        color: tileColor
      });

      newTileOverlay.mesh = new THREE.Mesh(geometry, material);
      newTileOverlay.mesh.visible = false;
      this.group.add(newTileOverlay.mesh);

      this.overlayPool.push(newTileOverlay);
    }

    this.updateAvailableOverlays();
  }

  isNearBy(tile) {
    return tile.distanceToCameraZ <= this.nearByDistanceZ
  }
  isInView(tile, frustum) {
    if (frustum == null) return true;
    return frustum.containsPoint(tile.pos);
  }

  updateAvailableOverlays(frustum) {
    this.availableOverlays = [];
    for (var i = 0; i < this.overlayPoolSize; i++) {
      const overlay = this.overlayPool[i];
      let isFree = false;
      if (overlay.tile == null) {
        isFree = true;
      } else {
        if ((overlay.tile.isVisible === false) || (overlay.tile.isAnimating === true)) {
          isFree = true;
        } else if (!this.isNearBy(overlay.tile)) {
          isFree = true;
        } else if (!this.isInView(overlay.tile, frustum)) {
          isFree = true;
        }
      }

      if (isFree) {
        if (overlay.tile !== null) {
          overlay.tile.overlay = null;
          overlay.tile.isOverlayed = false;
        }

        overlay.tile = null;
        overlay.mesh.visible = false;
        this.availableOverlays.push(overlay)
      }
    }
  }

  updateHighResTiles(tileGroups, frustum) {
    this.updateAvailableOverlays(frustum);

    // find tiles nearby
    let nearByTiles = [];
    let overlayedCount = 0;
    let visibleCount = 0;
    let nearbyCount = 0;
    let visibleAndNearbyCount = 0;
    let tileOne = null;
    for (var i = 0; i < tileGroups.length; i++) {
      const tileGroup = tileGroups[i];
      const nearByInGroup = tileGroup.tiles.filter((tile) => {
        return (
          (tile.isOverlayed === false) &&
          (tile.isVisible === true) &&
          (tile.isAnimating === false) &&
          (this.isNearBy(tile))
        );
      });
      nearByTiles = nearByTiles.concat(nearByInGroup);
    }

    // sort nearest to farthest
    nearByTiles.sort((a, b) => {
      a.nearByDistanceZ - b.nearByDistanceZ
    })

    const maxTilesToLoadPerUpdate = 5;
    let countTilesToDo = Math.min(nearByTiles.length, this.availableOverlays.length);
    countTilesToDo = Math.min(countTilesToDo, maxTilesToLoadPerUpdate);

    // console.log(nearByTiles.length, this.availableOverlays.length)

    for (var i = 0; i < countTilesToDo; i++) {
      const overlay = this.availableOverlays[i];

      // find the next that isn't overlayed yet
      const nextTile = nearByTiles.find((tile) => {
        const inView = this.isInView(tile, frustum)
        return (inView && !tile.isOverlayed);
      })

      if (nextTile) {
        this.overlayTileWithHighRes(overlay, nextTile);
      }
    }
  }

  overlayTileWithHighRes(overlay, tile) {
    overlay.tile = tile;
    overlay.mesh.visible = false;
    overlay.updateMeshPositionFromTile();

    tile.isOverlayed = true;

    this.totalLoaded++;

    const url = `${settings.serverURL}/tiles/thumbnails/${settings.highResSize}/${tile.imageID}.jpg`;
    const loadedImageID = tile.imageID;
    this.textureLoader.load(
      url,
      // on load
      (texture) => {
        if (overlay.tile !== null) {
          if (overlay.tile.imageID === loadedImageID) {
            overlay.mesh.visible = true;
            overlay.mesh.material.map = texture;
            if (settings.colorizeHighRes) {
              overlay.mesh.material.color.set(0xff00ff);
            } else {
              overlay.mesh.material.color.set(0xffffff);
            }
          }
        }
      }

    );
  }
}