const ElementId = {
  CLOSE_MODAL : 'close-info-modal',
  EXPLORE : 'explore',
  EXPLORE_CONTAINER : 'loader',
  HASHTAG_DESKTOP : 'hashtag-desktop',
  HASHTAG_MOBILE : 'hashtag-mobile',
  HEADER_BG : 'header-bg',
  INTRO_SCREEN : 'intro-screen',
  HOME_TITLE : 'home-title',
  INFO_MODAL : 'info-modal',
  INFO_MODAL_INNER : 'info-modal-inner',
  IMAGES_TO_DATE : 'images-to-date',
  LOGO : 'logo',
  OPEN_MODAL : 'open-info-modal',
  PHOTO : 'photo-modal-photo',
  PHOTO_MODAL : 'photo-modal',
  RESET : 'reset',
  REVEAL : 'reveal',
  REVEAL_BTN : 'reveal-button',
  SHARE_TRIGGER : 'share-trigger',
  SHARE_CONTAINER : 'share-container',
  LOAD_PROGRESS : 'progress',
  LOAD_SPINNER : 'loading-spinner',
  LOAD_SVG : 'loading-svg',
  VIGNETTE : 'vignette'
}

const ElementClass = {
  HIDDEN : 'is-hidden',
  ACTIVE : 'is-active',
  VISIBLE : 'is-visible',
  DISABLED : 'disabled',
  MOSAIC_ACTIVE : 'mosaic-active',
  ICON_ONLY : 'icon-only',
  LOADED : 'loaded'
}

const DATA_LABEL = 'data-label';
const DATA_SRC = 'data-src';
const SRC = 'src';

const Event = {
  CLICK : 'click'
}

const ScreenWidth = {
  LARGE : 1440,
  MEDIUM : 1025,
  MOBILE : 480
};

export default class UiInteraction {

  constructor() {
    this.introScreenActive = true;
    this.shareIconsVisible = false;

    this.exploreContainer =
        document.getElementById(ElementId.EXPLORE_CONTAINER);
    this.exploreButton = document.getElementById(ElementId.EXPLORE);
    this.introScreen = document.getElementById(ElementId.INTRO_SCREEN);
    this.progress = document.getElementById(ElementId.LOAD_PROGRESS);
    this.loadingSpinner = document.getElementById(ElementId.LOAD_SPINNER);
    this.loadingSVG = document.getElementById(ElementId.LOAD_SVG);

    this.homeTitle = document.getElementById(ElementId.HOME_TITLE);

    this.openModalButton = document.getElementById(ElementId.OPEN_MODAL);
    this.closeModalButton = document.getElementById(ElementId.CLOSE_MODAL);
    this.infoModal = document.getElementById(ElementId.INFO_MODAL);
    this.infoModalInner = document.getElementById(ElementId.INFO_MODAL_INNER);
    this.headerBg = document.getElementById(ElementId.HEADER_BG);

    this.imagesToDate = document.getElementById(ElementId.IMAGES_TO_DATE);

    this.photoModal = document.getElementById(ElementId.PHOTO_MODAL);
    this.photo = document.getElementById(ElementId.PHOTO);
    this.logo = document.getElementById(ElementId.LOGO);

    this.reveal = document.getElementById(ElementId.REVEAL);
    this.revealButton = document.getElementById(ElementId.REVEAL_BTN);
    this.reset = document.getElementById(ElementId.RESET);

    this.vignette = document.getElementById(ElementId.VIGNETTE);

    this.hashtagAnimationDesktop =
        document.getElementById(ElementId.HASHTAG_DESKTOP);
    this.hashtagAnimationMobile =
        document.getElementById(ElementId.HASHTAG_MOBILE);

    this.shareIconsToggle = document.getElementById(ElementId.SHARE_TRIGGER);
    this.shareIconsContainer =
        document.getElementById(ElementId.SHARE_CONTAINER);

    this.addEventListeners();
  }

  updateLoadProgress(loaded, total) {
    let percentage, percentageString, strokePercentage, strokeOffsetValue;

    percentage = (loaded / total) * 100;
    percentage = Math.ceil(percentage);

    if (percentage > 0 && percentage < 10) {
      percentageString = '0' + percentage + '%';
    } else {
      percentageString = percentage + '%';
    }

    strokeOffsetValue = 214;
    strokePercentage = 214 - (214 / (100 / percentage));

    if (loaded >= total) {
      this.progress.innerHTML = '100%';
      this.progress.classList.add(ElementClass.LOADED);
      this.loadingSpinner.style.strokeDashoffset = 0;
    } else {
      this.progress.innerHTML = percentageString;
      this.loadingSpinner.style.strokeDashoffset = strokePercentage;
    }
  }

  addAnimatedGif() {
    let src;
    if (this.isMobile()) {
      src = this.hashtagAnimationMobile.getAttribute(DATA_SRC);
      this.hashtagAnimationMobile.setAttribute(SRC, src);
      this.hashtagAnimationMobile.style.opacity = .9;

      this.hashtagAnimationDesktop.style.display = 'none';
    } else {
      src = this.hashtagAnimationDesktop.getAttribute(DATA_SRC);
      this.hashtagAnimationDesktop.setAttribute(SRC, src);
      this.hashtagAnimationDesktop.style.opacity = 1;

      this.hashtagAnimationMobile.style.display = 'none';
    }
  }

  /*
   * Display the reveal button
   */
  showRevealButton() { this.reveal.classList.add(ElementClass.ACTIVE); }

  addRevealEventListeners(callback) {
    if (this.revealButton) {
      this.revealButton.addEventListener('click', (e) => {
        this.reveal.classList.remove(ElementClass.ACTIVE);
        callback();
      })
    }
  }

  /*
   * Display the reset button
   */
  showResetButton() { this.reset.classList.add(ElementClass.ACTIVE); }

  addResetEventListeners(callback) {
    if (this.reset) {
      this.reset.addEventListener('click', (e) => {
        this.reset.classList.remove(ElementClass.ACTIVE)
        callback();
      })
    }
  }

  /*
   * Add click event listener for the explore button
   */
  addExploreEventListener(callback) {
    if (this.exploreButton) {
      this.exploreButton.classList.remove(ElementClass.DISABLED);

      this.exploreButton.addEventListener(Event.CLICK, (e) => {
        this.introScreen.classList.add(ElementClass.HIDDEN);

        this.logo.classList.add(ElementClass.VISIBLE);
        this.infoModal.classList.add(ElementClass.MOSAIC_ACTIVE);

        this.introScreenActive = false;
        this.vignette.classList.add(ElementClass.ACTIVE);
        this.headerBg.classList.add(ElementClass.MOSAIC_ACTIVE);

        // Absolutely ensure overlaying intro screen won't prevent interaction
        // on mosaic.
        // Allow time for explore button to fade out
        setTimeout(() => { this.introScreen.style.display = 'none'; }, 1100);

        callback();
      })
    }
  }

  updateInfoModalImageCount(total) {
    let totalString = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.imagesToDate.innerHTML = totalString;
  }

  openPhotoModal(imageURL) {
    this.photoModal.classList.add(ElementClass.ACTIVE);
    this.photo.style['background-image'] = `url('${imageURL}')`;
    if (this.reveal) {
      this.reveal.classList.add(ElementClass.HIDDEN);
    }
    if (this.reset) {
      this.reset.classList.add(ElementClass.HIDDEN);
    }
  }

  handleOpenModalState() {
    this.closeModalButton.classList.add(ElementClass.ACTIVE);
    this.homeTitle.classList.add(ElementClass.HIDDEN);
    this.exploreContainer.classList.add(ElementClass.HIDDEN);
    this.openModalButton.classList.add(ElementClass.HIDDEN);
    this.infoModal.classList.add(ElementClass.ACTIVE);

    this.reveal.classList.add(ElementClass.HIDDEN);
    this.reset.classList.add(ElementClass.HIDDEN);

    // Hide the photo modal
    if (this.photoModal) {
      this.photoModal.classList.add(ElementClass.HIDDEN);
    }

    this.headerBg.classList.add(ElementClass.VISIBLE);
  }

  handleClosedModalState() {
    this.closeModalButton.classList.remove(ElementClass.ACTIVE);
    this.homeTitle.classList.remove(ElementClass.HIDDEN);
    this.exploreContainer.classList.remove(ElementClass.HIDDEN);
    this.openModalButton.classList.remove(ElementClass.HIDDEN);
    this.infoModal.classList.remove(ElementClass.ACTIVE);

    this.reveal.classList.remove(ElementClass.HIDDEN);
    this.reset.classList.remove(ElementClass.HIDDEN);

    // Show the photo modal
    if (this.photoModal) {
      this.photoModal.classList.remove(ElementClass.HIDDEN);
    }

    this.headerBg.classList.remove(ElementClass.VISIBLE);
  }

  /*
   * Add DOM event listeners
   */
  addEventListeners() {
    // Handle opening information modal window
    if (this.openModalButton && this.infoModal && this.exploreContainer) {
      this.openModalButton.addEventListener(
          Event.CLICK, (e) => { this.handleOpenModalState(); });
    }

    // Handle closing information modal window using close icon
    if (this.closeModalButton && this.infoModal && this.exploreContainer) {
      this.closeModalButton.addEventListener(
          Event.CLICK, (e) => { this.handleClosedModalState(); })
    }

    // Handle closing information modal window when click outside circle
    if (this.infoModal) {
      this.infoModal.addEventListener(
          Event.CLICK, (e) => { this.handleClosedModalState(); });
    }

    // Prevent clicking modal text / content from closing modal
    if (this.infoModalInner) {
      this.infoModalInner.addEventListener(Event.CLICK,
                                           (e) => { e.stopPropagation(); });
    }

    // Handles hiding the photo modal
    if (this.photoModal) {
      this.photoModal.addEventListener(Event.CLICK, (e) => {
        if (e.target != this.photo) {
          if (this.photoModal.classList.contains(ElementClass.ACTIVE)) {
            this.photoModal.classList.remove(ElementClass.ACTIVE);
            this.reveal.classList.remove(ElementClass.HIDDEN);

            if (this.reveal) {
              this.reveal.classList.remove(ElementClass.HIDDEN);
            }
            if (this.reset) {
              this.reset.classList.remove(ElementClass.HIDDEN);
            }
          }
        }
      })
    }

    // Handle display of share icons
    //      TODO Also handle updating GA tracking text
    if (this.shareIconsToggle && this.shareIconsContainer) {
      this.shareIconsToggle.addEventListener(Event.CLICK, (e) => {
        if (!this.shareIconsVisible) {
          this.shareIconsContainer.classList.add(ElementClass.VISIBLE);
          this.shareIconsToggle.setAttribute(DATA_LABEL, 'Close Share');
        } else {
          this.shareIconsContainer.classList.remove(ElementClass.VISIBLE);
          this.shareIconsToggle.setAttribute(DATA_LABEL, 'Open Share');
        }

        this.shareIconsVisible = !this.shareIconsVisible;
      })
    }

    if (this.logo) {
      this.logo.addEventListener(Event.CLICK,
                                 (e) => { window.location.reload(); })
    }
  }

  isMobile() { return window.innerWidth < ScreenWidth.MOBILE; }
}
