import 'whatwg-fetch'
import settings from './settings'

export default {
  loadData(jsonFilename, path) {
    return new Promise(function(resolve, reject) {
      let url = path ? `${settings.serverURL}/${path}/${jsonFilename}` :
        `${settings.serverURL}/${jsonFilename}`;
      const request = new XMLHttpRequest();
      request.open('GET', url);
      request.send();
      request.onreadystatechange = (event) => {
        if (request.readyState == 4 && request.status == 200) {
          resolve(JSON.parse(request.responseText));
        } else if (request.status === 404) {
          reject(new Error('Problem JSON not found'));
        } else if (request.status === 401) {
          reject(new Error('Problem JSON request unauthorized'));
        }
      };
    });
  }
};