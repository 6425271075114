import * as THREE from 'three';
import Tile from './tile';
import SpriteSheet from './spriteSheet';
import settings from './../settings';
import sceneState from './../state';

import tileVertexShader from '../../assets/shaders/tile.vs';
import tileFragmentShader from '../../assets/shaders/tile.fs';

class SocialPost {
  constructor() {
    this.imageURL = '';
    this.username = '';
    this.brightness = 0;
    this.tiles = [];
  }
}

export default class TileGroup {
  constructor(id, cloudIndexOffset) {
    this.id = id;
    this.cloudIndexOffset = cloudIndexOffset;

    this.tiles = [];
    this.socialMediaPosts = [];
    this.visibleTileCount = 0;
    this.spriteSheet = null;

    this.group = new THREE.Group();
    //this.group.frustumCulled = false;

    //	this.pickMeshes = [];
    //	this.pickMeshesGroup = new THREE.Group();
    //this.pickMeshesGroup.visible = false;
    //	this.group.add(this.pickMeshesGroup);

    this.geometry = null;
    this.mesh = null;
    this.material = null;

    this.tilesOffsetAttribute = null;
    this.tileSpriteUVAttribute = null;
    this.tileVisibilityAttribute = null;
    this.tileColorIDAttribute = null;
    this.tileBrightnessDiffAttribute = null;

    this.countUniqueTiles = 0;
    this.countUnusedTiles = 0;

    this.textureLoader = new THREE.TextureLoader();
    this.textureLoader.setCrossOrigin('anonymous');

    this.tilesAnimating = false;
    this.needPositionUpdate = true;

    this.animationParams = {
      ratio: 0
    };
  }

  initFromJSON(json, cols, rows) {
    this.tiles = [];

    this.countUnusedTiles = 0;

    for (let id in json.values) {
      const socialData = json.values[id];

      const socialDetails = socialData.s;
      const spriteIndex = parseInt(socialDetails.i);

      const socialPost = new SocialPost();
      socialPost.imageURL = socialDetails.url;
      socialPost.username = socialDetails.un;
      socialPost.brightness = socialDetails.b;
      socialPost.spriteIndex = spriteIndex;
      socialPost.spriteSheetIndex = socialDetails.ref;

      const imageID = socialDetails.id ? socialDetails.id : socialData.id;

      this.countUniqueTiles++;
      if (socialData.v.length == 0) this.countUnusedTiles++;

      let count = 0;
      socialData.v.forEach((tileData) => {
        const mosaicIndex = tileData[0];
        const mosaicBrightness = tileData[1];

        const tile = new Tile();
        tile.index = this.tiles.length;
        tile.tileGroup = this;
        tile.spriteIndex = spriteIndex;
        tile.mosaicIndex = mosaicIndex;
        tile.mosaicCol = mosaicIndex % cols;
        tile.mosaicRow = Math.floor(mosaicIndex / cols);
        tile.mosaicBrightness = mosaicBrightness;
        tile.socialPost = socialPost;
        tile.imageID = imageID;

        const mosaicPos = new THREE.Vector2(tile.mosaicCol, tile.mosaicRow)
        const mosaicCenter = new THREE.Vector2(cols / 2, rows / 2)
        tile.distanceFromMosaicCenter = mosaicPos.distanceTo(mosaicCenter);

        tile.spritesheetID = socialDetails.ref;
        //tile.spritesheetID = tile.spritesheetID_default;

        this.tiles.push(tile);

        socialPost.tiles.push(tile);
        count++;
      })

      // Sort tiles by nearness to center
      socialPost.tiles.sort((a, b) => {
        return a.distanceFromMosaicCenter - b.distanceFromMosaicCenter;
      });

      this.socialMediaPosts.push(socialPost);
    }

    this.buildSpriteSheet();
    this.buildMaterial(32, this.spriteSheet.texture);
    this.rebuild();
    this.setSpriteSheet(this.spriteSheet);
  }

  hideRepeatedTiles(ratioToShow) {
    for (var i = 0; i < this.socialMediaPosts.length; i++) {
      const socialMediaPost = this.socialMediaPosts[i];
      const tiles = socialMediaPost.tiles;

      const socialMediaPostIndex = (socialMediaPost.spriteSheetIndex * this.spriteSheet.tilesPerSheet) +
        socialMediaPost.spriteIndex;

      const indexCutoff = 1 + (tiles.length * ratioToShow);
      for (var j = 0; j < tiles.length; j++) {
        const tile = tiles[j];

        if (j >= indexCutoff) {
          tile.isHiddenRepeat = true;
        } else {
          tile.isHiddenRepeat = false;
        }
        //tile.setSpriteSheet(spriteSheet);
      }

    }
  }


  rebuild() {
    this.buildGeometry();
    this.buildMesh();
    this.updateTileUVs();
  }

  buildMesh() {
    if (this.mesh) this.group.remove(this.mesh);

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.frustumCulled = false;

    this.group.add(this.mesh);

    // pick meshes
    //const planeGeometry = new THREE.PlaneBufferGeometry( settings.tileSize, settings.tileSize, 1, 1 );
    //var material = new THREE.MeshBasicMaterial( { color: 0xffff00,  wireframe:true } );


    // this.tiles.forEach((tile) => {
    // 	const pickMesh = new THREE.Mesh(planeGeometry, material);
    // 	//pickMesh.visible = false;
    // 	//pickMesh.matrixAutoUpdate = false;
    // 	this.pickMeshes.push(pickMesh);
    // 	this.pickMeshesGroup.add(pickMesh);
    // 	tile.pickMesh = pickMesh;
    // });

  }

  buildSpriteSheet() {
    this.spriteSheet = new SpriteSheet();
    this.spriteSheet.initFromJSON(this.id)
    const url = settings.serverURL + '/' + settings.versionPath + '/' + this.spriteSheet.filename;

    //this.spriteSheet.texture = this.textureLoader.load(url);
    this.spriteSheet.texture = new THREE.Texture();
  }
  setSpriteSheet(spriteSheet) {
    for (var i = 0; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      tile.setSpriteSheet(spriteSheet);
    }

    this.material.uniforms.map.value = spriteSheet.texture;
    this.material.uniforms.spriteWidth.value = spriteSheet.tileSize / spriteSheet.width;
    this.material.uniforms.spriteHeight.value = spriteSheet.tileSize / spriteSheet.height;
    this.material.needsUpdate = true;

    this.updateTileUVs();
  }
  updateTileUVs() {
    for (var i = 0; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      if (tile.isVisible && tile.spriteSheet) {
        this.tileSpriteUVAttribute.setXY(tile.attributeIndex, tile.spriteUV.u, tile.spriteUV.v);
      }
    }
    this.tileSpriteUVAttribute.needsUpdate = true;
  }

  loadTexture(url) {
    return new Promise((resolve, reject) => {
      this.textureLoader.load(
        url,
        texture => {
          resolve(texture)
        },
        undefined, // onProgress callback not supported from r84
        err => reject(err)
      )
    })
  }

  loadLoResTexture() {
    const sheetFilename = settings.useLowResSprites ?
      this.spriteSheet.filenameLowRes : this.spriteSheet.filename;
    const url = settings.serverURL + '/' + settings.versionPath + '/' + sheetFilename;
    return this.loadTexture(url)
      .then((texture) => {
        this.spriteSheet.texture = texture;
        this.material.uniforms.map.value = texture;
        this.material.needsUpdate = true;
      })
  }
  loadHighResTexture() {
    //return false;
    const url = settings.serverURL + '/' + settings.versionPath + '/' + this.spriteSheet.filenameHighRes;
    return this.loadTexture(url)
      .then((texture) => {
        this.spriteSheet.texture = texture;
        this.material.uniforms.map.value = texture;
        this.material.needsUpdate = true;
      })
  }

  buildMaterial(spriteSize, texture) {
    this.material = new THREE.RawShaderMaterial({
      uniforms: {
        map: {
          value: texture
        },
        spriteWidth: {
          value: spriteSize / 2048
        },
        spriteHeight: {
          value: spriteSize / 2048
        },
        doPickDraw: {
          value: false
        },
        fogNear: {
          value: sceneState.fogNear
        },
        fogFar: {
          value: sceneState.fogFar
        },
        brightnessAdjustmentRatio: {
          value: settings.brightnessFix
        }
      },
      vertexShader: tileVertexShader,
      fragmentShader: tileFragmentShader,
      //side: THREE.Front,
      transparent: false
    });
  }

  buildGeometry() {
    if (this.geometry) this.geometry.dispose();

    this.visibleTileCount = 0;
    for (var i = 0; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      if (tile.isVisible) {
        tile.attributeIndex = this.visibleTileCount;
        this.visibleTileCount++;
        tile.needsPositionUpdate = true;
      }
    }
    this.tilesAnimating = true;

    const tileCount = this.visibleTileCount;

    this.geometry = new THREE.InstancedBufferGeometry();
    this.geometry.copy(new THREE.PlaneBufferGeometry(settings.tileSize, settings.tileSize, 1, 1));

    var offsets = new Float32Array(tileCount * 3);
    var spriteUVs = new Float32Array(tileCount * 2);
    var colorIDs = new Float32Array(tileCount * 3);
    var brightnessDiff = new Float32Array(tileCount * 1);

    this.tileColorIDAttribute = new THREE.InstancedBufferAttribute(colorIDs, 3);
    this.tileSpriteUVAttribute = new THREE.InstancedBufferAttribute(spriteUVs, 2);
    this.tilesOffsetAttribute = new THREE.InstancedBufferAttribute(offsets, 3);
    this.tileBrightnessDiffAttribute = new THREE.InstancedBufferAttribute(brightnessDiff, 1);

    const color = new THREE.Color();
    for (var i = 0; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      if (tile.isVisible) {

        color.setHex(tile.index + this.cloudIndexOffset + 1)
        this.tileColorIDAttribute.setXYZ(tile.attributeIndex, color.r, color.g, color.b);

        const brightnessDiff = (tile.mosaicBrightness - tile.socialPost.brightness) / 255;
        this.tileBrightnessDiffAttribute.setX(tile.attributeIndex, brightnessDiff);
      }
    }

    this.geometry.addAttribute('offset', this.tilesOffsetAttribute);
    this.geometry.addAttribute('spriteUV', this.tileSpriteUVAttribute);
    this.geometry.addAttribute('colorID', this.tileColorIDAttribute);
    this.geometry.addAttribute('brightnessDiff', this.tileBrightnessDiffAttribute);
  }

  updateTileVisibilityBasedOnCulling(nonCulledTiles) {
    let allVisible = false;
    if (nonCulledTiles == null) {
      allVisible = true;
    } else {
      for (var i = 0; i < nonCulledTiles.length; i++) {
        const tile = nonCulledTiles[i];
        tile.wasInFrustrumThisUpdate = true;
      }
    }

    let attributeUpdated = false;
    for (var i = 0; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      tile.isCulled = !tile.wasInFrustrumThisUpdate;

      const isVisible = (!tile.isCulled || allVisible) && !tile.isHiddenRepeat;
      if (tile.isVisible !== isVisible) {
        tile.isVisible = isVisible;
        attributeUpdated = true;
        //visible++;
      }
      tile.wasInFrustrumThisUpdate = false; // ready for next time
    }

    //console.log(visible)

    if (attributeUpdated)
      this.rebuild();
  }

  updateVisibleTilesDistance(position) {
    for (var i = 0; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      if (tile.isVisible) {
        tile.distanceToCameraZ = position.z - tile.pos.z;
      }
    }
  }

  startAnimation(time) {
    //console.log('startAnimation', time);
    if (time === undefined) {
      time = 2;
    }

    if (time > 0) {
      time = (time / 2) + ((time / 2) * Math.random());
    }

    this.tilesAnimating = true;
    this.animationParams.ratio = 0;
    TweenMax.killTweensOf(this.animationParams);

    for (var i = 0; i < this.tiles.length; i++) {
      const tile = this.tiles[i];
      tile.animationVector.subVectors(tile.targetPos, tile.startPos);
      tile.isAnimating = true;
    }

    TweenMax.to(this.animationParams, time, {
      ratio: 1,
      ease: Power2.easeOut,
      onComplete: () => {
        this.tilesAnimating = false;
        this.needPositionUpdate = true; // so final position is updated
        for (var i = 0; i < this.tiles.length; i++) {
          const tile = this.tiles[i];
          tile.pos.copy(tile.targetPos);
          tile.isAnimating = false;
          tile.needsPositionUpdate = true;
        }
      }
    })
  }
  update() {
    let needsUpdate = false;

    if (this.tilesAnimating || this.needPositionUpdate) {
      for (var i = 0; i < this.tiles.length; i++) {
        const tile = this.tiles[i];
        if (tile.isAnimating || tile.needsPositionUpdate) {
          needsUpdate = true;
          // if (tile.pos.distanceToSquared(tile.targetPos) > settings.tileAnimationStopCutoff) {
          //   tile.pos.lerp(tile.targetPos, 0.03);
          // } else {
          //   tile.pos.copy(tile.targetPos)
          //   // after this, we're done
          //   tile.isAnimating = false;
          // }
          if (tile.isAnimating) {
            tile.pos.copy(tile.startPos);
            tile.pos.addScaledVector(tile.animationVector, this.animationParams.ratio)
          }

          if (tile.isVisible) {
            this.tilesOffsetAttribute.setXYZ(tile.attributeIndex, tile.pos.x, tile.pos.y, tile.pos.z);
          }
          tile.needsPositionUpdate = false;
        }

      }

      // only update attributes if something is moving
      this.tilesOffsetAttribute.needsUpdate = needsUpdate;
      this.needPositionUpdate = false;
    }

  }
}