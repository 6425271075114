import {TweenMax} from "gsap/TweenMax"
import emitter from 'mitt';
import * as THREE from 'three';
import MapControls from 'three/examples/js/controls/MapControls'
import EffectComposer from 'three/examples/js/postprocessing/EffectComposer'
import MaskPass from 'three/examples/js/postprocessing/MaskPass'
import RenderPass from 'three/examples/js/postprocessing/RenderPass'
import ShaderPass from 'three/examples/js/postprocessing/ShaderPass'
import SMAAPass from 'three/examples/js/postprocessing/SMAAPass'
// SMAA Stuff
import CopyShader from 'three/examples/js/shaders/CopyShader'
import SMAAShader from 'three/examples/js/shaders/SMAAShader'

import server from '../server'

import settings from './../settings'
import sceneState from './../state'
import TileCloud from './tileCloud'

// import {Quad} from "gsap/Eases"

let renderer;
let scene;
let camera;
let cameraHelper;
let debugCamera;
let controls;
let tileCloud;
let composer, pass;

let pickingTexture, pickingScene;
let cameraRotationWhileClose;

// let settings;

export default class SceneManager {
  constructor() {
    this.tileCloud = null;
    this.isUserInteracting = false;
  }

  prepare() {
    this.initState();

    scene = new THREE.Scene();
    scene.fog = new THREE.Fog(0x000000, sceneState.fogNear, sceneState.fogFar)

    try {
      renderer = new THREE.WebGLRenderer({antialias : false, stencil : false});
    } catch (error) {
      console.warn('problem creating THREE.WebGLRenderer', error)
      return false;
    }

    // renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setPixelRatio(1);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.domElement.setAttribute("id", "mainCanvas");
    const containerDom = document.getElementById('canvasContainer');
    containerDom.appendChild(renderer.domElement);

    const fieldOfView = 75;
    const aspectRatio = window.innerWidth / window.innerHeight;
    const nearPlane = 12;
    const farPlane = 50000;
    camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane,
                                         farPlane);

    cameraHelper = new THREE.CameraHelper(camera);
    scene.add(cameraHelper);
    // camera.position.set(0,-80,200)
    // camera.up.set(0,0,1);
    this.updateCamera();
    // camera.up.rotateX(Math.PI / 8)

    debugCamera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio,
                                              nearPlane, farPlane);
    debugCamera.position.set(100, 200, 400);
    debugCamera.lookAt(camera.position);

    controls = new THREE.MapControls(camera, renderer.domElement);
    this.updateControlSettings();

    cameraRotationWhileClose = new THREE.Euler();
    cameraRotationWhileClose.copy(camera.rotation)

    tileCloud = new TileCloud();
    this.tileCloud = tileCloud;
    // this.updateSceneTilt();

    scene.add(tileCloud.objectGroup)
    scene.add(tileCloud.octreeHelper)

    // special scene to allow mouse picking on the GPU with instanced objects
    // from:
    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_interactive_cubes_gpu.html
    pickingTexture =
        new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight);

    // Postprocessing
    composer = new THREE.EffectComposer(renderer);
    composer.addPass(new THREE.RenderPass(scene, camera));

    pass = new THREE.SMAAPass(window.innerWidth, window.innerHeight);
    pass.renderToScreen = true;
    composer.addPass(pass);

    this.updateFog();

    tileCloud.updateMosaicRandConfiguration();
    tileCloud.updateConfiguration(0);

    return true;
  }

  getSocialInfoForTile(index) {
    const tile = this.tileCloud.getTileByOverallIndex(index);
    if (tile != null)
      return tile.socialPost;
  }
  updateCamera() {
    if (controls) {
      controls.reset();
    }
    camera.position.set(0, settings.cameraStartY, settings.cameraStartZ);
    camera.up.set(0, 0, 1);

    if (controls) {
      controls.saveState()
      // controls.reset();
    }
  }
  // updateSceneTilt() {
  //   tileCloud.objectGroup.setRotationFromEuler( new THREE.Euler(
  // settings.sceneTilt, 0, 0))
  // }

  updateControlSettings() {
    if (!controls)
      return;

    controls.enableDamping = true;
    controls.dampingFactor = settings.panFriction;
    controls.panSpeed = settings.panSpeed;
    controls.minDistance = sceneState.zoomMin;
    controls.maxDistance = sceneState.zoomMax;
    controls.enableZoom = true;
    // controls.screenSpacePanning = false;
    controls.enableRotate = false;
  }

  initState() {
    sceneState.fogNear = 0; // start all foggy
    sceneState.fogFar = 0;
    sceneState.zoomMin = settings.defaultZoomMin;
    sceneState.zoomMax = settings.defaultZoomMax;
    sceneState.gap = settings.defaultGap;
    sceneState.zVariance = settings.defaultZVariance;
    sceneState.tileLayout = 3;
    sceneState.doCulling = settings.enableCulling;
  }
  doSiteOpening() {
    camera.position.z = settings.cameraStartZ + 20;

    TweenMax.to(sceneState, 2.2, {
      fogNear : settings.defaultFogNear,
      fogFar : settings.defaultFogFar,
      ease : Power2.easeIn,
      onUpdate : function() { this.updateFog(); }.bind(this),

    });
  }

  doStartScene() {
    TweenMax.to(camera.position, 2.0, {
      z : settings.cameraStartZ,
      delay : 0.45,
      ease : Power1.easeOut,
    })
  }

  doReset(immediately) {
    const time = immediately ? 0 : 3;

    controls.enabled = false;
    controls.dispose();
    controls = null;

    TweenMax.to(sceneState, time, {
      fogNear : settings.defaultFogNear,
      fogFar : settings.defaultFogFar,
      tileLayout : 3,
      gap : settings.defaultGap,
      zVariance : settings.defaultZVariance,
      onUpdate : function() { this.updateFog(); }.bind(this),
      onComplete : () => {
        tileCloud.updateMosaicRandConfiguration();
        tileCloud.updateConfiguration(2);
      }
    });

    TweenMax.to(camera.position, time, {
      x : 0,
      y : settings.cameraStartY,
      z : settings.cameraStartZ,
      onComplete : () => {
        sceneState.zoomMin = settings.defaultZoomMin;
        sceneState.zoomMax = settings.defaultZoomMax;

        controls = new THREE.MapControls(camera, renderer.domElement);
        this.updateControlSettings();
        controls.enabled = true;
      }
    });

    TweenMax.to(camera.rotation, time, {
      x : cameraRotationWhileClose.x,
      y : cameraRotationWhileClose.y,
      z : cameraRotationWhileClose.z
    });
  }

  doReveal() {
    // disable culling while we animate out
    // to avoid hiccups
    this.disableCulling();

    controls.enabled = false;
    controls.dispose();
    controls = null;

    sceneState.zoomMax = 4000;
    this.updateControlSettings();

    sceneState.tileLayout = 3;
    sceneState.zVariance = 2000;
    sceneState.gap = 0;
    tileCloud.updateMosaicRandConfiguration();
    tileCloud.updateConfiguration(2);

    TweenMax.to(camera.rotation, 5, {x : 0, y : 0, z : 0})
    TweenMax.to(camera.position, 5, {
      z : 4000,
      x : 0,
      y : 0,
      delay : 0,
      ease : Quad.easeInOut,
      onComplete : () => {
        controls = new THREE.MapControls(camera, renderer.domElement);
        this.updateControlSettings();
        controls.enabled = true;
      }
    });

    TweenMax.to(sceneState, 3, {
      fogNear : 10000,
      fogFar : 10000,
      //   ease: Quad.easeInOut,
      onUpdate : function() { this.updateFog(); }.bind(this)
    })

    TweenMax.set(sceneState, {
      zVariance : 0,
      delay : 3.5,
      onComplete : () => {
        sceneState.tileLayout = 3;
        tileCloud.updateMosaicRandConfiguration();
        tileCloud.updateConfiguration(3);
      }
    })

    TweenMax.set(sceneState, {
      delay : 8,
      onComplete : () => {
        this.enableCulling();
        tileCloud.needToRecalculateOctree = true;
      }
    })
  }
  disableCulling() {
    tileCloud.resetAllCulling();
    sceneState.doCulling = false;
  }
  enableCulling() { sceneState.doCulling = settings.enableCulling; }
  updateFog() {
    tileCloud.updateFog();
    scene.fog.near = sceneState.fogNear;
    scene.fog.far = sceneState.fogFar;
  }
  loadJsonAssets() {
    //

    const promises = [];
    const cacheBust = Date.now();

    settings.versionPath = 'static_mosic_build';

    return server.loadData(`sprites.json`, settings.versionPath)
        .then((sheetJson) => {
          /*
          sceneState.canReveal = (sheetJson['reveal'] === true);
          sceneState.canReveal = sceneState.canReveal ||
          settings.revealOverrideMode;

          if (sheetJson['repeats']) {
            sceneState.repeatRatio = sheetJson['repeats'];
          }*/
          sceneState.canReveal = true;
          sceneState.repeatRatio = 1;

          // load from JSON param
          if (sheetJson['high'] === false) {
            settings.enableHighResOverlay = false;
          } else if (sheetJson['high'] === true) {
            settings.enableHighResOverlay = true;
          }
          // emergency shut off
          if (settings.emergencyTileShutoff) {
            settings.enableHighResOverlay = false;
          }
          return tileCloud.loadJsonAssets(sheetJson)
        });
  }
  loadLoResImages(onProgress) {
    const promiseArray = tileCloud.loadLoResImages();

    const totalToLoad = tileCloud.uniqueSocialPosts;
    let loaded = 0;
    if (onProgress) {
      onProgress(loaded, totalToLoad);
    }

    promiseArray.forEach((promise) => {promise.then(() => {
                           loaded += 4096;

                           if (onProgress) {
                             onProgress(loaded, totalToLoad);
                           }
                         })})

    return Promise.all(promiseArray);
  }

  canReveal() { return sceneState.canReveal; }

  resize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    composer.setSize(window.innerWidth, window.innerHeight);
    pickingTexture.setSize(window.innerWidth, window.innerHeight);
  }

  update() {
    if (controls)
      controls.update();

    const frustum = this.getCameraFrustrum();
    tileCloud.setCameraFrustum(frustum);
    tileCloud.setCameraPosition(camera.position)

    tileCloud.isUserInteracting = this.isUserInteracting;
    tileCloud.update();
  }

  render() {
    tileCloud.prepForNormalRender();

    tileCloud.octreeHelper.visible = settings.showOctreeHelper;

    if (settings.renderDebugCamera) {
      if (scene.fog.near != 0) {
        sceneState.fogNear = 0;
        sceneState.fogFar = 10000;
        this.updateFog();
      }
      cameraHelper.visible = true;
      renderer.render(scene, debugCamera);
    } else {
      cameraHelper.visible = false;
      if (settings.doAntiAliasing) {
        composer.render();
      } else {
        renderer.render(scene, camera);
      }
    }
  }

  getCameraFrustrum() {
    // use a slightly wider FOV so our frustum is a bit bigger
    camera.fov = 80;

    camera.updateProjectionMatrix();
    camera.updateMatrix();      // make sure camera's local matrix is updated
    camera.updateMatrixWorld(); // make sure camera's world matrix is updated
    camera.matrixWorldInverse.getInverse(camera.matrixWorld);

    var frustum = new THREE.Frustum();
    frustum.setFromMatrix(new THREE.Matrix4().multiplyMatrices(
        camera.projectionMatrix, camera.matrixWorldInverse));

    camera.fov = 75;
    camera.updateProjectionMatrix();

    return frustum;
  }

  isCameraFarFromMosaic() {
    if (camera.position.z > settings.farFromMosaicZ)
      return true;

    return false;
  }

  pick(x, y) {
    var pixelBuffer = new Uint8Array(4);

    tileCloud.prepForPickRender();
    renderer.render(scene, camera, pickingTexture);

    renderer.readRenderTargetPixels(
        pickingTexture, x, pickingTexture.height - y, 1, 1, pixelBuffer);

    var id = (pixelBuffer[0] << 16) | (pixelBuffer[1] << 8) | (pixelBuffer[2]);
    id -= 1; // no index 0, because of black

    return id;
  }
}
